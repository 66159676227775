import {
    Component, Input, ChangeDetectorRef,
    OnInit,
    OnDestroy
} from "@angular/core";
import { ReportService } from "app/services/report.service";
import { Subscription ,  Subject } from "rxjs";
import { DataSource } from "@angular/cdk/collections";
import { SimpleDataSource } from "app/data/datasets/simple.datasource";
import { Config } from "app/config/config.service";
import { FormatHelper } from "app/shared/utils/format-helper";
import { RouletteDetail } from "app/data/entities/roulette-detail";
import { RouletteBet } from "app/data/entities/roulette-bet";
import { PlayerGameService } from "app/generic-list/custom-actions/player-game-actions/player-game.service";
import { RowIterator } from "app/data/row-iterator";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-roulette-details",
    templateUrl: "roulette-details.component.html",
    styleUrls: ["roulette-details.component.css"]
})
export class RouletteDetailsComponent implements OnInit, OnDestroy {
    @Input() set rowData(value: RowIterator) {
        if (value) {
            this._rowData = value;
            this.getGameData();
        }
    }

    @Input() set tokenhash(value: string) {
        if (value) {
            this._token = value;
        }
    }

    @Input() set datahash(value: string) {
        if (value) {
            this._encodedId = value;
            this.getGameData();
        }
    }

    private _rowData: RowIterator;
    private _encodedId: string;
    private _token: string;

    get row(): any {
        if (this._rowData) {
            return this._rowData.currentRow;
        }   
    }

    betsColumns = ["rouletteBetDescription", "amount"];
    private bets: Subject<RouletteBet[]>;

    detailsColumns = ["totalBet", "totalPayout", "spinResult"];

    public rouletteDetail: Subject<RouletteDetail[]>;

    public bShowMessageError: boolean = false;
    public errorCode: string="";
    public date: string;
    public time: string;
    private subscriptions: Subscription = new Subscription();
    private currency: string;
    private resultNumber: number;
    private playerGameServiceSubscription: Subscription;

    constructor(
        private reportService: ReportService, 
        private config: Config,
        private playerGameService: PlayerGameService, 
        private cd: ChangeDetectorRef,
        private translate: TranslateService
    ) {
        this.playerGameServiceSubscription = this.playerGameService.refresh$.subscribe(() => {
            if (this._rowData.currentRow.externalGameId === Number(this.config.gameDetailsReport.roulette)){
                this.getGameData();
            }
        });
        this.bets = new Subject();
        this.rouletteDetail = new Subject();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.playerGameServiceSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    get betsDatasource(): DataSource<RouletteBet> {
        return new SimpleDataSource(this.bets.asObservable());
    }

    get detailsDatasource(): DataSource<RouletteDetail> {
        return new SimpleDataSource(this.rouletteDetail.asObservable());
    }

    getGameData() {
        const rouletteDetail = (this._rowData) ? 
            this.reportService.getRouletteDetail(this.row.idStr) : 
            this.reportService.getRouletteDetailEncoded(this._encodedId, this._token);

        rouletteDetail.subscribe(item => {

            if (this.translate.currentLang === undefined){
                this.translate.currentLang = item.languagecode ? item.languagecode : "en";
                this.translate.setDefaultLang(this.translate.currentLang);
            }  

            this.date = FormatHelper.shortDate(item.dateTime, this.translate.currentLang);
            this.time = FormatHelper.shortTime(item.dateTime, this.translate.currentLang);
            this.rouletteDetail.next([item]);
            this.bets.next(item.bets);
            this.currency = item.currency;
            this.resultNumber = Number(item.spinResult);

        },
        err => {
            this.translate.currentLang = "en";
            this.translate.setDefaultLang(this.translate.currentLang);
            this.bShowMessageError = true;
            this.errorCode =  err.status.toString();
            this.cd.detectChanges();
        });
        this.cd.markForCheck();
    }

    public getImgUrl(url: string): string {
        return this.config.imageCdnUrl + url;
    }

    public getNumber(line: RouletteBet): string {
        return line.tableId >= 0 ? line.tableId.toString() : "";
    }

    public getPayout(amount: number): string {
        return FormatHelper.currency(amount, this.currency, this.translate.currentLang);
    }

    public getYesOrNo(bool: boolean): string {
        const yesOrNo = bool ? "app.shared.labels.yes" : "app.shared.labels.no"
        return this.translate.instant(yesOrNo);
    } 

    public getNumberDescription(value: any): string {
        return this.translate.instant(value);
    }

    public getResultColor(): string {
           
        const reds = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
        const blacks = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35];

        if (reds.includes(this.resultNumber)){
            return "result-color-red";
        }
        else if (blacks.includes(this.resultNumber)){
            return "result-color-black";
        }

        return "result-color-green";
    }

}
