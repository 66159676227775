import {
    Component, Input, ChangeDetectorRef,
    OnInit,
    OnDestroy
} from "@angular/core";
import { ReportService } from "app/services/report.service";
import { Subscription ,  Subject } from "rxjs";
import { DataSource } from "@angular/cdk/collections";
import { SimpleDataSource } from "app/data/datasets/simple.datasource";
import { Config } from "app/config/config.service";
import { FormatHelper } from "app/shared/utils/format-helper";
import { BlackjackDetail } from "app/data/entities/blackjack-detail";
import { BlackjackHand } from "app/data/entities/blackjack-hand";
import { BlackjackIcon } from "app/data/entities/blackjack-icon";
import { PlayerGameService } from "app/generic-list/custom-actions/player-game-actions/player-game.service";
import { RowIterator } from "app/data/row-iterator";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-blackjack-details",
    templateUrl: "blackjack-details.component.html",
    styleUrls: ["blackjack-details.component.css"]
})
export class BlackjackDetailsComponent implements OnInit, OnDestroy {

    @Input() set rowData(value: RowIterator) {
        if (value) {
            this._rowData = value;
            this.getGameData();
        }
    }

    @Input() set tokenhash(value: string) {
        if (value) {
            this._token = value;
        }
    }

    @Input() set datahash(value: string) {
        if (value) {
            this._encodedId = value;
            this.getGameData();
        }
    }

    private _rowData: RowIterator;
    private _encodedId: string;
    private _token: string;

    get row(): any {
        if (this._rowData) {
            return this._rowData.currentRow;
        }
        return;
    }

    displayedColumns = ["handNumber", "playerCards", "doubled", "surrender", "totalBets", "payout"];
    private hands: Subject<BlackjackHand[]>;

    playerBetsColumns = ["bet", "insurance", "insurancePayout", "sideBet", "sideBetPayout"];

    public playerBets: Subject<BlackjackDetail[]>;

    public bShowMessageError: boolean = false;
    public errorCode: string="";
    public date: string;
    public time: string;
    public dealerCards: Array<string>;
    public icons: Array<BlackjackIcon>;
    private subscriptions: Subscription = new Subscription();
    private currency: string;
    private playerGameServiceSubscription: Subscription;

    constructor(
        private reportService: ReportService, 
        private config: Config,
        private playerGameService: PlayerGameService, 
        private cd: ChangeDetectorRef,
        private translate: TranslateService
    ) {

        this.playerGameServiceSubscription = this.playerGameService.refresh$.subscribe(() => {
            if (this._rowData.currentRow.externalGameId === Number(this.config.gameDetailsReport.blackjack)){
                this.getGameData();
            }
        });
        this.hands = new Subject();
        this.playerBets = new Subject();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.playerGameServiceSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    get handsDatasource(): DataSource<BlackjackHand> {
        return new SimpleDataSource(this.hands.asObservable());
    }

    get betsDatasource(): DataSource<BlackjackDetail> {
        return new SimpleDataSource(this.playerBets.asObservable());
    }

    getGameData() {
        const blackjackDetail = (this._rowData) ? 
            this.reportService.getBlackjackDetail(this.row.idStr) : 
            this.reportService.getBlackjackDetailEncoded(this._encodedId, this._token);

        blackjackDetail.subscribe(bjd => {

            if (this.translate.currentLang === undefined){
                this.translate.currentLang = bjd.languagecode ? bjd.languagecode : "en";
                this.translate.setDefaultLang(this.translate.currentLang);
            }            

            this.date = FormatHelper.shortDate(bjd.dateTime, this.translate.currentLang);
            this.time = FormatHelper.shortTime(bjd.dateTime, this.translate.currentLang);
            this.icons = bjd.icons;
            this.dealerCards = bjd.dealerCards.split(" ").filter(dc => dc.length > 0);
            this.playerBets.next([bjd]);
            this.hands.next(bjd.hands);
            this.currency = bjd.currency;
        },
        err => {
            this.translate.currentLang = "en";
            this.translate.setDefaultLang(this.translate.currentLang);
            this.bShowMessageError = true;
            this.errorCode =  err.status.toString();
            this.cd.detectChanges();
        });
        this.cd.markForCheck();
    }

    public getImgUrl(url: string): string {
        return this.config.imageCdnUrl + url;
    }

    public getNumber(line: BlackjackHand): string {
        return line.handNumber >= 0 ? line.handNumber.toString() : "";
    }

    public getPayout(amount: number): string {
        return FormatHelper.currency(amount, this.currency, this.translate.currentLang);
    }

    public getYesOrNo(bool: boolean): string {
        const yesOrNo = bool ? "app.shared.labels.yes" : "app.shared.labels.no"
        return this.translate.instant(yesOrNo);
    }      

    public getMessageError(error:string):string{
        this.bShowMessageError = true;
        if (error)
        {
            switch (error){
                case "400":
                    return "app.shared.labels.tokenExpired";
                    break;
                case "404":
                    return 'app.casinos.generic-list.error-no-data';
                    break;
                default:
                    return "app.auth.login.error-try-again-later";
            }
        }
      
    }

}
